export const environment = {
	production: true,
	pwaEnabled: true,

	inactivity: {
		idle: 1800,
		timeout: 300,
		ping: 120
	},

	auth0: {
		domain: 'portalauth.pointhealthtech.com', //'pointhealth-serviceportal.us.auth0.com',
		clientID: 'DxEebgDcyqi0tgvVdCIojDBINTpD3Kg1',
		// request access to this audience (api) at user authentication time
		audience: 'https://portalapi.pointhealthtech.com' // note, this is id, not url
	},

	api: {
		host: 'https://portalapi.pointhealthtech.com',
		other: [
			'https://portalapi.pointhealthtech.com',
			'https://portalapi-pointhealthtech-com-dzfuhahmd5feb4cy.z01.azurefd.net',
			'https://pointhealth-portalapi-central-prd.azurewebsites.net',
			'https://pointhealth-portalapi-east-prd.azurewebsites.net'
		]
	},

	sites: {
		memberWeb: 'https://app.pointhealthtech.com',
	},

	tiny: {
		// TODO: REPLACE WITH PROD PH KEY
		apiKey: 'wpf8b9ehbbxr74dvl7rqghkr66371jc4gw7mh0433cxhtqbv'
	},

	google: {
		staticMapsApiKey: 'AIzaSyDL2nDUzvjcmjXUQx-v6sJdcYF9_ppLEQQ',
		javaScriptMapsApiKey: 'AIzaSyBrymw05KO29N0loLeiEeEwOMV4oI8tOW8'
	},

	sentry: {
		dsn: 'https://98e1eb8df9854eedba4a20ead02498be@o493298.ingest.sentry.io/4504838276448256',
		tracePropagationTargets: ['localhost', 'portalapi.pointhealthtech.com', /^\//],
		tracesSampleRate: 0.5,
		environment: 'PRD'
	},

	// used for some one-off client-specific customizations
	clientCodes: {
		SederaClientCode: 'CL-62BF44DD'
	}
};
