<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Prescription<br />Request</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body pb-2">
				<div class="row mb-3 g-0">
					<div class="col-24">
						<app-medication-select [(drugName)]="case.drugName"
											   [(brandName)]="case.brandName"
											   [(form)]="case.form"
											   [(dosage)]="case.dosage"
											   [(quantity)]="case.quantity"
											   [(frequency)]="case.frequency"
											   [(brandNameOnly)]="case.brandNameOnly">
						</app-medication-select>
					</div>
				</div>

				<hr />

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="zipCode">
						Zip code
					</label>

					<div class="col-5">
						<app-zipcode-input [id]="'zipCode'"
										   [name]="'zipCode'"
										   [(ngModel)]="case.zipCode">
						</app-zipcode-input>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="canTravelDistanceMiles">
						Travel distance (mi)
					</label>

					<div class="col-8">
						<input type="text"
							   class="form-control"
							   id="canTravelDistanceMiles"
							   name="canTravelDistanceMiles"
							   [(ngModel)]="case.canTravelDistanceMiles"
							   placeholder="Travel distance" />

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
