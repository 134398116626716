<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Facility/Provider Request</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body pb-2">
				<div class="row mb-3">
					<label class="col-9 col-form-label" for="facilityTypeCode">
						Facility type
					</label>

					<div class="col-12">
						<ng-select class="custom-ng-select"
								   id="facilityTypeCode"
								   name="facilityTypeCode"
								   [items]="facilityTypes"
								   [bindLabel]="'name'"
								   [bindValue]="'code'"
								   [(ngModel)]="case.facilityTypeCode"
								   [clearable]="false"
								   [multiple]="false"
								   [searchable]="false"
								   [loading]="loadingFacilityTypes"
								   [loadingText]="'loading facility types...'"
								   [notFoundText]="'no facility type matching your search'"
								   [placeholder]="'Facility type'">
						</ng-select>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="specialtyCode">
						Specialty
					</label>

					<div class="col-15">
						<app-specialty-input [(specialtyName)]="case.specialtyName"
											 (specialtyChange)="onSpecialtyChange($event)">
						</app-specialty-input>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="cptCode">
						Procedure
					</label>

					<div class="col-15">
						<app-procedure-input [(procedureName)]="case.procedureName"
											 (procedureChange)="onProcedureChange($event)">
						</app-procedure-input>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="preferredGenderCode">
						Preferred gender
					</label>

					<div class="col-8">
						<ng-select class="custom-ng-select"
								   id="preferredGenderCode"
								   name="preferredGenderCode"
								   [items]="genders"
								   [bindLabel]="'name'"
								   [bindValue]="'code'"
								   [(ngModel)]="case.preferredGenderCode"
								   [clearable]="false"
								   [multiple]="false"
								   [searchable]="false"
								   [loading]="loadingGenders"
								   [loadingText]="'loading genders...'"
								   [placeholder]="'Preferred gender'">
						</ng-select>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="otherPreferences">
						Other preferences
					</label>

					<div class="col-15">
						<textarea class="form-control"
								  id="otherPreferences"
								  name="otherPreferences"
								  [(ngModel)]="case.otherPreferences"
								  placeholder="Other preferences"></textarea>
					</div>
				</div>

				<hr />

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="zipCode">
						Zip code
					</label>

					<div class="col-5">
						<app-zipcode-input [id]="'zipCode'"
										   [name]="'zipCode'"
										   [(ngModel)]="case.zipCode">
						</app-zipcode-input>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="canTravelDistanceMiles">
						Travel distance (mi)
					</label>

					<div class="col-8">
						<input type="text"
							   class="form-control"
							   id="canTravelDistanceMiles"
							   name="canTravelDistanceMiles"
							   [(ngModel)]="case.canTravelDistanceMiles"
							   placeholder="Travel distance" />

					</div>
				</div>

				<hr />

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="description">
						Order of importance
					</label>

					<div class="col-10">
						<app-order-edit [items]="case.priorities"
										[labelProperty]="'priorityName'"
										[orderProperty]="'priorityOrder'"
										[inlineEdit]="false"
										[vertical]="true"
										(save)="onPrioritiesChange($event)">
						</app-order-edit>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
